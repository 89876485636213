var myMapPoints = {
    "type": "FeatureCollection",
    "name": "KGH pt",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": [
        { "type": "Feature", "properties": { "Name": "Restaurant", "imgsrc": "images/mayura.webp", "type": "amenityRestaurant", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.684330, 11.402362, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Stay", "imgsrc": "images/mayura.webp", "type": "amenity", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.684066672562906, 11.402266630607199, 0.0] } },
       // { "type": "Feature", "properties": { "Name": "glass house", "imgsrc": "images/glassHouse.webp", "type": "hotspot", "description": " ", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.683923994279397, 11.4018308424279, 0.0] } },
       // { "type": "Feature", "properties": { "Name": "Valley view", "imgsrc": "images/garden.webp", "type": "hotspot", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.684244063938607, 11.4016874029289, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Garden parking", "imgsrc": "images/entrance.webp", "type": "amenity", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.682645163155996, 11.4013989699341, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Toilets", "imgsrc": "images/toilets.png", "type": "amenityToilet", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.682447, 11.400940, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Toilets", "imgsrc": "images/toilets.png", "type": "amenityToilet", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.681650, 11.399881, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Toilets", "imgsrc": "images/toilets.png", "type": "amenityToilet", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.682964, 11.400771, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Toilets", "imgsrc": "images/toilets.png", "type": "amenityToilet", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.683556, 11.399986, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Tea estate", "imgsrc": "images/teaGarden.webp", "type": "hotspot", "description": "Patches of beatiful tea garden that is known in the Nilgiri hills", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.683124489517994, 11.4005209136781, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Ooty Italian Garden", "imgsrc": "images/italianGarden.webp", "type": "hotspot", "description": "It is a style of garden developed in Rome and Florence in the 15th century.", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.683937113399395, 11.400258238607901, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Maze Garden", "imgsrc": "images/mazeGarden.webp", "type": "hotspot", "description": "It is a winding, labyrinth-like outdoor garden, flanked by what appear to be green walls. This interesting park of European design creates confusion and amusement among the tourists.", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.683710589717094, 11.400109171276901, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Entrance", "imgsrc": "images/entrance.webp", "type": "amenity", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.682944141287706, 11.4015511613466, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Suspension Bridge", "imgsrc": "images/suspensionBridge.webp", "type": "hotspot", "description": "The  is a very complementary structure to the 38-acre park. Connecting two steep valleys, this suspension bridge is 5 feet wide and 300 feet long, 70-100 feet above the ground, and offers a breathtaking view of the park. The suspension bridge will connect the tea plantations on both sides", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.682237045435798, 11.399406585746499, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Pond", "imgsrc": "images/ponds.webp", "type": "hotspot", "description": "7 small lakes have been built in the tea garden valley of the park and around 3 crore liters of water is being collected from these 7 ponds. Attractive Different Style Water Cascades are constructed to allow water to flow from one pond to another. Different species of ducks and kohi fish are kept in these water ponds which are major attractions for tourists and children visiting the park.", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.6825636799215, 11.399596676472401, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Chasing Fountain", "imgsrc": "images/chasingFountain.webp", "type": "hotspot", "description": "A special Chasing Fountain modeled after the one in Dubai's Miracle Garden, can be enjoyed by visitors in the evening under a decorative lighting system. The 108 mtr long dancing fountain has two loops and 960 channels with a total of 240 chasing nozzles along with three circular type fountains, six moving butterfly type fountains, six moving parrot type fountains, ten single jet fountains, one sun bust and four other different types of fountains. Fountain pond built on half an acre with a total capacity of 24 lakh liters of water has been constructed for this purpose", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.681833509311801, 11.3989518633229, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Temperate flower pots", "imgsrc": "images/temperateFlowerPots.webp", "type": "hotspot", "description": "Around 20,000 Number of Pots different species of Temperate flowers are displayed in the park's 1500 sq m polyhouse for year-round display", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.683169731421998, 11.400788594426899, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Sunken Garden", "imgsrc": "images/sunkenGarden.webp", "type": "hotspot", "description": "It is an Amphi-theatre (circular stage) with flowers of various species. Its Center is a deep place surrounded by highlands.", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.683868218802999, 11.4009160698632, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Stay", "imgsrc": "images/mayura.webp", "type": "amenity", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.683612226894198, 11.4022219631459, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Stay", "imgsrc": "images/mayura.webp", "type": "amenity", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.682930547055406, 11.4019602309898, 0.0] } },
        { "type": "Feature", "properties": { "Name": "Toilets", "imgsrc": "images/toilets.png", "type": "amenityToilet", "description": "", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.684506286464099, 11.4021571235133, 0.0] } },
        // Client asked to remove this
        // { "type": "Feature", "properties": { "Name": "1", "imgsrc": "images/garden.webp", "type": "qr", "description": "You are here, you can close this menu and lookSuspension Bridge at the garden map to learn more..", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "QR code points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.682921381742801, 11.401583405325299, 0.0] } },
        // { "type": "Feature", "properties": { "Name": "2", "imgsrc": "images/garden.webp", "type": "qr", "description": "You are here, you can close this menu and look at the garden map to learn more..", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "QR code points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.683730423760906, 11.401736541896099, 0.0] } },
        // { "type": "Feature", "properties": { "Name": "3", "imgsrc": "images/garden.webp", "type": "qr", "description": "You are here, you can close this menu and look at the garden map to learn more..", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "QR code points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.684149520445402, 11.402227027159199, 0.0] } },
        // { "type": "Feature", "properties": { "Name": "4", "imgsrc": "images/garden.webp", "type": "qr", "description": "You are here, you can close this menu and look at the garden map to learn more..", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "QR code points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.683879759154706, 11.4006193446452, 0.0] } },
        // { "type": "Feature", "properties": { "Name": "5", "imgsrc": "images/garden.webp", "type": "qr", "description": "You are here, you can close this menu and look at the garden map to learn more..", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "QR code points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.683526335218801, 11.4008931541007, 0.0] } },
        // { "type": "Feature", "properties": { "Name": "6", "imgsrc": "images/garden.webp", "type": "qr", "description": "You are here, you can close this menu and look at the garden map to learn more..", "timestamp": null, "begin": null, "end": null, "altitudeMode": null, "tessellate": -1, "extrude": 0, "visibility": -1, "drawOrder": null, "icon": null, "folders": "QR code points", "altitude": 0.0, "alt_mode": "", "time_begin": "", "time_end": "", "time_when": "" }, "geometry": { "type": "Point", "coordinates": [76.682355900888197, 11.3995486737674, 0.0] } }
    ]
}

export default myMapPoints

